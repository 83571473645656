import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap } from 'rxjs';
import { ApiService } from './api.service';
import { Domains } from '../shared/models/domain-problems/Domains';
import { PlayerGamePayload } from '../shared/models/payloads/PlayerGamePayload';
import { ReportIssuePayload } from '../shared/models/payloads/ReportIssuePayload';

@Injectable({
  providedIn: 'root'
})
export class ProblemService {

  private readonly problemsUrl: string = '/v1/problems';
  private _domains: Domains[];

  constructor(private apiService: ApiService) { }

  public generateMathProblem(): Observable<any> {
    return this.apiService.get(`${this.problemsUrl}/generate-math-problem/`);
  }

  public verifyUserAnswer(body: any): Observable<any> {
    return this.apiService.post(`${this.problemsUrl}/user-game/`, body);
  }

  public getDomains(): Observable<any> {
    return this.apiService.get(`${this.problemsUrl}/domains/`).pipe(
      tap((response: any) => {
        this._domains = response.results.map((r: any) => new Domains(r));
      }),
      catchError(() => {
        return of(null)
      })
    );
  }

  public startNewGame(payload: PlayerGamePayload): Observable<any> {
    return this.apiService.post(`${this.problemsUrl}/player-game/`, payload);
  }

  public getGameDetails(gameId: number): Observable<any> {
    return this.apiService.get(`${this.problemsUrl}/player-game/${gameId}/`);
  }

  public generateMathProblemByGameId(gameId: number): Observable<any> {
    return this.apiService.get(`${this.problemsUrl}/generate-math-problem/`, { game_id: gameId });
  }

  public startGame(gameId: number): Observable<any> {
    return this.apiService.get(`${this.problemsUrl}/player-game/${gameId}/start/`);
  }

  public acceptRejectGameInvite(gameId: number, accepted: boolean): Observable<any> {
    return this.apiService.post(`${this.problemsUrl}/player-game/${gameId}/player-invite/`, { accepted })
  }

  public getGameHistory(isCompleted?: boolean): Observable<any> {
    let url: string = `${this.problemsUrl}/player-game/`;
    if (isCompleted !== undefined) {
      url = `${this.problemsUrl}/player-game/?is_completed=${isCompleted}`;
    }
    return this.apiService.get(url);
  }

  public getProblemPlayerStats(): Observable<any> {
    return this.apiService.get(`${this.problemsUrl}/player-stats/`);
  }

  public rematch(gameId: number): Observable<any> {
    return this.apiService.get(`${this.problemsUrl}/player-game/${gameId}/rematch/`);
  }

  public getReportedIssues(): Observable<any> {
    return this.apiService.get(`${this.problemsUrl}/problem-issue-reports/`);
  }

  public reportProblemIssue(payload: ReportIssuePayload): Observable<any> {
    return this.apiService.post(`${this.problemsUrl}/problem-issue-reports/`, payload)
  }

  get domains(): Domains[] {
    return this._domains;
  }
}
