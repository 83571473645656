import { DomainsDto } from "./DomainsDto";

export class Domains {
  id: number;
  uid: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  isActive: boolean;
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
  name: string;
  description: string;
  averageDifficulty: number;
  selected?: boolean;

  constructor(data: DomainsDto) {
    this.id = data.id;
    this.uid = data.uid;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.deletedAt = data.deleted_at;
    this.isActive = data.is_active;
    this.createdBy = data.created_by;
    this.updatedBy = data.updated_by;
    this.deletedBy = data.deleted_by;
    this.name = data.name;
    this.description = data.description;
    this.averageDifficulty = data.average_difficulty;
  }

  select(): void {
    this.selected = true;
  }

  deselect(): void {
    this.selected = false;
  }
}
